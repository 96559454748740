import React, { createContext, useContext } from "react";
import { graphql, useStaticQuery } from "gatsby";


const data = graphql`
query DynamicPagesQuery {
    allMarkdownRemark(
            filter: {fileAbsolutePath: {regex: "/pages/"}}
            sort: {fields: frontmatter___order, order: ASC}
          ) {
            nodes {
              frontmatter {
                title
                url
                metaTitle
                metaDescription
              }
            }
          }
} 
`
export const usePageQuery = () => useContext(PageContext);

const PageContext = createContext([]);

const PageContextProvider = ({ children }) => {

  const { allMarkdownRemark } = useStaticQuery(data);


  return (
    <PageContext.Provider value={{
      allPageInfo: allMarkdownRemark.nodes.map(({ frontmatter: { title, url, metaTitle, metaDescription} }) => { return { title, url, metaTitle, metaDescription } }) || []
    }}>
      {children}
    </PageContext.Provider>
  )
};

export { PageContextProvider };
