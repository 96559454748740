import React, { createContext, useContext } from "react";
import { useOpen } from "../hooks/useOpen";

const ImageModalContext = createContext();

export const useImageModal = () => useContext(ImageModalContext);

const ImageModalProvider = ({ children }) => {

    const { isOpen, setIsOpen, handleIsOpen, handleIsOpenModal } = useOpen(false)

    return (
        <ImageModalContext.Provider value={{
            isOpen,
            setIsOpen,
            handleIsOpen,
            handleIsOpenModal
        }}>
            {children}
        </ImageModalContext.Provider>
    )
};

export { ImageModalProvider };
