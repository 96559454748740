import React from "react";
import { ImageModalProvider } from "./src/contextHook/useImageModal";
import { PageContextProvider } from "./src/query/markdownQuery/usePageQuery";
import { SiteMetaDataContextProvider } from "./src/query/siteMetaDataQuery/useSiteMetaDataQuery";

export const wrapRootElement = ({ element }) => (
        <SiteMetaDataContextProvider>
                <PageContextProvider>
                                <ImageModalProvider>
                                    {element}
                                </ImageModalProvider>
                </PageContextProvider>
        </SiteMetaDataContextProvider>
);
